import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import ReportsFiltering from "../../components/ReportsFiltering";
import axios from "axios";
import moment from 'moment';
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export function EventDate(props) {
    return (
        <>
        <Grid key={`startEventDate`} style={{width: '23%', margin: '1%'}}>
            <div style={{
                maxWidth: '100%'
            }}>
                <span>Event Start Date</span>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline1"
                        value={props.startValue ? new Date(props.startValue) : null}
                        onChange={(e) => props.eventDateChange('eventStartDate', moment(e).format('YYYY-MM-DD'))}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </Grid>
        
        <Grid key={`endEventDate`} style={{width: '23%', margin: '1%'}}>
            <div style={{
                maxWidth: '100%'
            }}>
                <span>Event End Date</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline1"
                        value={props.endValue ? new Date(props.endValue) : null}
                        onChange={(e) => props.eventDateChange('eventEndDate', moment(e).format('YYYY-MM-DD'))}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </Grid>
        </>
    );
}

class Webinar extends React.Component {
    results = React.createRef();
    state = {
        config: null,
        eventStartDate: undefined,
        eventEndDate: undefined,
        selectedDimensions: {
            dimension1: 'Client',
            dimension2: 'Event Date',
            dimension3: 'Event Name',
            dimension4: 'Product',
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {
            "Include internal traffic sources": true
        }
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/webinar`).then(response => {
            this.setState({
                ...this.state,
                config: response.data
            })
        })
    }

    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }
        return str;
    }

    getFilterParams() {
        let str = '';
        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }

    getColumns() {
        let cols = Object.assign([], this.state.config.columns);
        cols.unshift(this.state.selectedDimensions.dimension4);
        cols.unshift(this.state.selectedDimensions.dimension3);
        cols.unshift(this.state.selectedDimensions.dimension2);
        cols.unshift(this.state.selectedDimensions.dimension1);
        return cols;
    }

    eventDateChange(type, date) {
        this.setState({
            ...this.state,
            [type]: date
        })
    }

    getEventDateFilter() {
        let str = '';
        if(this.state.eventStartDate) str += `&eventDateStartDate=${this.state.eventStartDate}`;
        if(this.state.eventEndDate) str += `&eventDateEndDate=${this.state.eventEndDate}`;
        return str;
    }

    setHiddenCols(hiddenCols) {
        let config = {...this.state.config};
        config.defaultHiddenColumns = hiddenCols;
        this.setState({config: config});
    }

    render() {
        let cols = [];
        if(this.state.config !== null) {
            cols = this.getColumns();
        }

        let groupingCols = [];
        [this.state.selectedDimensions.dimension1, 
            this.state.selectedDimensions.dimension2, 
            this.state.selectedDimensions.dimension3, 
            this.state.selectedDimensions.dimension4
        ].map(d => d ? groupingCols.push(d) : false)
        
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ? 
                            <div className="webinar__report" style={style.tabContainer}>
                                <Results 
                                    ref={this.results}
                                    sort={{
                                        column: cols[1],
                                        asc: false
                                    }}
                                    defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                    drillDownOptions={{
                                        columns: this.state.config.drillDownColumns,
                                        queryParams: this.getFilterParams() + this.getDimensionParams()
                                    }}
                                    drillDownFormatting={this.state.config.drillDownFormatting}
                                    groupingOptions={{
                                        cols: groupingCols,
                                        startingRow: 0
                                    }}
                                    columns={cols} 
                                    title={this.state.config.title}
                                    url={process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }${this.getFilterParams()}${this.getEventDateFilter()}`}
                                    exportCSVConfig={{
                                        name:'webinar',
                                        url: process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }${this.getFilterParams()}`
                                    }}
                                    columnsFormat={this.state.config.columnsFormat}
                                    filters={{
                                        selected: this.state.selectedDimensions, 
                                        additionalFilters: this.state.additionalFilters
                                    }}
                                    dimensions={this.state.config.dimensions}
                                    onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }
                                >
                                    <h3>{this.state.config.title}</h3>
                                    <ReportsFiltering
                                        columns={cols}
                                        defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                        onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }
                                        onSelect={(selected, filters) => this.onSelect(selected, filters)}
                                        selected={{
                                            dimension1: 'Client',
                                            dimension2: 'Event Date',
                                            dimension3: 'Event Name',
                                            dimension4: 'Product',
                                            startDate: this.state.selectedDimensions.startDate,
                                            endDate: this.state.selectedDimensions.endDate
                                        }}
                                        type="webinar"
                                        config={this.state.config}
                                    >
                                        <EventDate 
                                            startValue={this.state.eventStartDate}
                                            endValue={this.state.eventEndDate}
                                            eventDateChange={(type, date) => this.eventDateChange(type, date)}
                                        />
                                    </ReportsFiltering>
                                </Results>
                                <ScrollTopLeft />
                            </div>
                        : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default Webinar;
